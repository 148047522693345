<template>
  <select
    class="form-select"
    aria-label=""
    :name="name"
    @change="
      handleChange($event);
      $emit('changeSelect', JSON.parse($event.target.value));
    "
    :disabled="disabled"
    :id="id"
    @click="setTouched(true)"
  >
    <option value="null" :selected="inputValue === null ? true : false">
      {{ placeholder ? placeholder : "-" }}
    </option>
    <option
      v-for="option in arrayFiltrato"
      :key="Object.values(option)[0]"
      :value="JSON.stringify(option)"
      :selected="
        Object.values(option)[0] === inputValue
          ? true
          : Object.values(option)[0] === parseInt(value)
          ? true
          : Object.values(option)[1] === inputValue
          ? true
          : Object.values(option)[0] == inputValue // risolve problema comparazione tipi codP
          ? true
          : false
      "
    >
      {{ option[parameter] ? option[parameter] : Object.values(option)[1] }}
    </option>
  </select>
  <p
    class="help-message"
    v-if="errorMessage && meta.touched"
    :style="style"
    style="color: red"
  >
    {{ errorMessage }}
  </p>
</template>

<script>
import { useField } from "vee-validate";
import { watch, toRef } from "vue";

export default {
  name: "select-input",
  emits: ["changeSelect"],
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    successMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    campiLocked: {
      type: String,
    },
    style: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: false,
    },
    reset: {
      type: Boolean,
    },
    parameter: {
      type: String,
    },
    cittadinanza_sportiva_persona: {
      type: Number,
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      resetField,
      setTouched,
    } = useField(toRef(props, "name"));
    // meta.valid = false;
    inputValue.value = toRef(props, "value").value;

    watch(
      () => props.reset,
      () => {
        resetField({
          inputValue: null, // ✅
        });
      }
    );
    watch(
      () => props.value,
      (changedValue) => {
        setTouched(true);
        inputValue.value = changedValue;
      }
    );

    return {
      setTouched,
      resetField,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  computed: {
    arrayFiltrato() {
      const array = [];

      if (this.cittadinanza_sportiva_persona == 0) {
        this.options.forEach((element) => {
          array.push(element);
        });
      } else {
        this.options.forEach((element) => {
          if (!element.attiva_con_citt_sportiva_italiana) {
            array.push(element);
          }
        });
      }
      return array;
    },
  },
};
</script>

<style scoped>
.form-select {
  height: 28px;
}
</style>

<template>
  <div>
    <input
      v-if="!textArea"
      :name="name"
      :id="name"
      :disabled="disabled ? true : false"
      :type="type"
      :class="type === 'checkbox' ? 'form-check-input' : 'form-control'"
      :value="inputValue"
      :placeholder="placeholder"
      aria-label=""
      @input="
        handleChange($event);
        $emit('InputEvent', inputValue);
        $emit('focusEvent');
      "
      @change="type === 'radio' ? handleChange($event) : ''"
      @blur="handleBlur"
      @click="type === 'checkbox' ? setCheckbox($event) : ''"
      @focus="$emit('focusEvent', inputValue)"
      @focusout="$emit('focusoutEvent', inputValue)"
      :style="style"
      :checked="checked"
      :maxlength="maxLength"
      data-vv-as="Address Line 1"
    />
    <textarea
      v-else
      :type="type"
      class="form-control"
      :placeholder="placeholder"
      aria-label=""
      :value="inputValue"
      @input="handleChange"
      @blur="handleBlur"
      :style="style"
    />
    <p
      class="help-message"
      v-show="errorMessage || meta.valid"
      :style="style"
      style="color: red"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { useField } from "vee-validate";

export default {
  name: "text-input",
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    successMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    style: {
      type: String,
      default: "",
    },
    textArea: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: String,
    },
  },
  emits: ["changeEvent", "focusEvent", "InputEvent", "focusoutEvent"],
  setup(props, { emit }) {
    const {
      value: inputValue = props.value != "" ? props.value : "",
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value != null ? props.value : "",
    });

    const setCheckbox = (e) => {
      emit("changeEvent", e.target.checked);
      handleChange(e.target.checked);
    };

    return {
      setCheckbox,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>

<style scoped>
input.form-control {
  height: 28px !important;
}
</style>

import services from "../axios/dbManag";

export const addPersona = (persona) => {
  return services.dbManag
    .post("/anagrafiche/persone/add", {
      ...persona,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const updatePersona = (persona) => {
  return services.dbManag
    .post("/anagrafiche/persone/edit", {
      ...persona,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const viewAttivitaSportiva = (tessere_sportive_utente, anno) => {
  return services.dbManag
    .post("/attivita-sportiva/get", {
      tessere_sportive_utente,
      anno,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

<template>
  <div
    style="position: relative; width: 100%"
    v-click-away="onClickAway"
    v-show="list && list.length"
  >
    <ul class="list">
      <li v-if="!list" class="text-hover-primary">loading...</li>
      <li
        v-else
        tabindex="0"
        class="text-hover-primary"
        style="cursor: pointer"
        v-for="option in list"
        :key="Object.values(option)[0]"
        :value="Object.values(option)[0]"
        @keypress.enter="
          $emit('selectedElement', {
            value: Object.values(option)[0],
            name: Object.values(option)[1],
          })
        "
        @click.prevent="
          $emit('selectedElement', {
            value: Object.values(option)[0],
            name: Object.values(option)[1],
          })
        "
        @touchstart.prevent="
          $emit('selectedElement', {
            value: Object.values(option)[0],
            name: Object.values(option)[1],
          })
        "
      >
        <a>{{ Object.values(option)[1] }} </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { directive } from "vue3-click-away";

export default {
  name: "list-comuni",
  props: ["list", "visible"],
  emits: ["selectedLegale", "hideList", "aggiungiPersona", "selectedElement"],
  directives: {
    ClickAway: directive,
  },
  setup(props, { emit }) {
    const onClickAway = () => {
      emit("hideList");
    };
    return { onClickAway };
  },
};
</script>

<style>
.list {
  position: absolute;
  z-index: 999;
  background-color: #f3f6f8;
  border: 1px solid #a3a7b8;
  border-radius: 0.475rem;
  list-style-type: none;
  width: 100%;
  padding-top: 1rem;
  margin-top: 0.5rem;
  padding-bottom: 1rem;
}
</style>
